import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CardCarousel from "./CardCarousel";

const HomeSectionData = (props) => {
  const { item, kay } = props;


  const [value, setValue] = useState(0);

  const [FilterData, setFilterData] = useState([]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const chooseATab = (sItem) => {
    setFilterData(sItem?.flash_cards);
  };



  useEffect(() => {
    if(item?.sub_category && item?.sub_category.length > 0 ){
        chooseATab(item?.sub_category[0])
    }
  }, []);
  useEffect(() => {
    
  }, [FilterData, value]);



  if (item?.flash_cards_count > 0) {
    let secBg = "#d7d9d9";
    if (kay % 2) {
      secBg = "#39a189";
    }

    return (
      <>
        <div
          key={kay}
          className="w-full section-cat"
          style={{ backgroundColor: secBg }}
          id={`section${kay}`}
        >
          <div className="container mx-auto py-10 pb-16">
            <div className="grid grid-cols-1 gap-8">
              <h3
                className="text-5xl font-bold text-center mb-2"
                style={{
                  color: item?.bg_color ? item?.bg_color : "#000",
                }}
              >
                {item.name}
              </h3>
            </div>

            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {item?.sub_category.map((sItem, sKey) => {
                  return (
                    <Tab
                      className={`chapterTab ${sKey === value ? 'chapterTabActive' : ''}`}
                      label={sItem?.name}
                      key={sKey}
                      onClick={() => chooseATab(sItem)}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <Box sx={{ paddingBottom:5 }}>
              {FilterData.length > 0 ? (
                <>
                  <CardCarousel  key={`kay0_${kay}`} items={FilterData} />
                </>
              ) : (
                <>
                <Typography sx={{ 
                    paddingLeft: 6,
                    fontWeight:'700',
                    color:'#000',
                    position:'absolute',
                    
                 }}><span style={{ fontWeight:'600' }}>To be updated!</span></Typography>
                 
                </>
              )}
            </Box>

            <div className="view-all">
              <Link to={`/book/${btoa(item?.id)}`}>View More</Link>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default HomeSectionData;
