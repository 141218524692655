import React, { useEffect, useState } from "react";
import SingleCardUI from "./SingleCardUI";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

const CardCarousel = (props) => {
  const { items } = props;

  const [SlidesPerView, setSlidesPerView] = useState(4);
  const itemSize = () => {
    if (window.innerWidth < 480) {
      setSlidesPerView(1);
    } else if (window.innerWidth < 680) {
      setSlidesPerView(2);
    } else if (window.innerWidth < 880) {
      setSlidesPerView(3);
    } else {
      setSlidesPerView(4);
    }
  };
  useEffect(() => {
    itemSize();
    window.addEventListener("resize", function () {
      itemSize();
    });
    // console.warn('SlidesPerView >>>> ', SlidesPerView, window.innerWidth);
  }, [SlidesPerView]);

  
  return (
    <>
      <div>
        <div className="carousel-container">
          <Swiper
            className="external-buttons"
            modules={[Navigation]}
            spaceBetween={10}
            // slidesPerView={SlidesPerView}
            navigation
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
                // spaceBetween: 50,
              },
              1280: {
                slidesPerView: 4,
                // spaceBetween: 50,
              },
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
            {items.map((item, k) => {
              return (
                <>
                  <SwiperSlide key={k} className="py-8 px-4">
                    <SingleCardUI item={item} />
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default CardCarousel;
