import { bannersRequest, categoriesDetailRequest, categoriesRequest, pageRequest } from "../../utils/serverRequest";

export const bannerAction = (req) => async (dispatch) => {
    try {
        dispatch({
            type: 'BannerRequest'
        });
        const { data } = await bannersRequest(req);
        dispatch({
            type: 'BannerSuccess', payload: data
        });
    } catch (error) {

        var errorMsg;
        if (error.response.data?.Message) {
            errorMsg = error.response.data?.Message;
        } else {
            errorMsg = error.response.data?.msg;
        }

        dispatch({
            type: 'BannerFailure', payload: errorMsg
        });
    }
};
export const CategoriesAction = (req={}) => async (dispatch) => {
    
    try {
        dispatch({
            type: 'CategoriesRequest'
        });
        const { data } = await categoriesRequest(req);
        dispatch({
            type: 'CategoriesSuccess', payload: data
        });
    } catch (error) {

        var errorMsg;
        if (error.response.data?.Message) {
            errorMsg = error.response.data?.Message;
        } else {
            errorMsg = error.response.data?.msg;
        }

        // dispatch({ type: FAVORITE_FAIL, payload: errorMsg });
        dispatch({
            type: 'CategoriesFailure', payload: errorMsg
        });
    }
};

export const CategoriesDetailAction = (req={}) => async (dispatch) => {
    
    try {
        dispatch({
            type: 'CategoryDetailRequest'
        });
        const { data } = await categoriesDetailRequest(req);
        dispatch({
            type: 'CategoryDetailSuccess', payload: data
        });
    } catch (error) {

        var errorMsg;
        if (error.response.data?.Message) {
            errorMsg = error.response.data?.Message;
        } else {
            errorMsg = error.response.data?.msg;
        }

        // dispatch({ type: FAVORITE_FAIL, payload: errorMsg });
        dispatch({
            type: 'CategoryDetailFailure', payload: errorMsg
        });
    }
};

export const PagesAction = (req={}) => async (dispatch) => {
    
    try {
        dispatch({
            type: 'PageRequest'
        });
        const { data } = await pageRequest(req);
        dispatch({
            type: 'PageSuccess', payload: data
        });
    } catch (error) {

        var errorMsg;
        if (error.response.data?.Message) {
            errorMsg = error.response.data?.Message;
        } else {
            errorMsg = error.response.data?.msg;
        }

        // dispatch({ type: FAVORITE_FAIL, payload: errorMsg });
        dispatch({
            type: 'PageFailure', payload: errorMsg
        });
    }
};
