import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as RouteLink } from "react-router-dom";
import { LOGO } from "../images";

const Navbar = () => {
  const { loading: categoriesLoading, data: categoriesData } = useSelector(
    (state) => state.CategoriesReducer
  );

  const { loading: pageLoading, data: pageData } = useSelector(
    (state) => state.PageReducer
  );

  useEffect(() => {
    // console.log(categoriesData.data);
  }, [categoriesLoading]);
  useEffect(() => {}, [pageLoading]);

  return (
    <>
      {/* <nav className="bg-gray-800 p-2 mt-0 fixed w-full z-10 top-0">
        <div className="container mx-auto flex flex-wrap items-center">
            <div className="flex w-full md:w-1/2 justify-center md:justify-start text-white font-extrabold">
                <a className="text-white no-underline hover:text-white hover:no-underline" href="#">
                    <span className="text-2xl pl-2"><i className="em em-grinning"></i> Brand McBrandface</span>
                </a>
            </div>
            <div className="flex w-full pt-2 content-center justify-between md:w-1/2 md:justify-end">
                <ul className="list-reset flex justify-between flex-1 md:flex-none items-center">
                  <li className="mr-3">
                    <a className="inline-block py-2 px-4 text-white no-underline" href="#">Active</a>
                  </li>
                  <li className="mr-3">
                    <a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
                  </li>
                  <li className="mr-3">
                    <a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
                  </li>
                    <li className="mr-3">
                    <a className="inline-block text-gray-600 no-underline hover:text-gray-200 hover:text-underline py-2 px-4" href="#">link</a>
                  </li>
                </ul>
            </div>
        </div>
    </nav> */}
      <nav
        style={{ zIndex: 9 }}
        className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded dark:bg-gray-900 sticky fixed top-0"
      >
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <RouteLink to="/" className="flex items-center">
            <img
              src={LOGO}
              className="mr-3 h-12"
              alt="Meinwortschatz.com"
            />
          </RouteLink>
          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-default"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>

          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="flex flex-col p-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {!categoriesLoading &&
                categoriesData.data.map((item, k) => {
                  if (item?.flash_cards_count > 0) {
                    return (
                      <>
                      <RouteLink
                      key={k}
                      to={`/book/${btoa(item?.id)}`}
                    >
                      <li>
                        <a
                          href={`/book/${btoa(item?.id)}`}
                          className="block text-xl font-semibold py-2 pr-4 pl-3 rounded md:bg-transparent  md:p-0 dark:text-white"
                          aria-current="page"
                          style={{ color: item?.bg_color, textTransform:'capitalize' }}
                        >
                          {item?.name}
                        </a>
                      </li>
                    </RouteLink>
{/*                     
                        <Link
                          key={k}
                          activeClassName="active"
                          to={`section${k}`}
                          spy={true}
                          smooth={true}
                          offset={-50}
                          duration={500}
                        >
                          <li>
                            <a
                              href="#"
                              className="block text-xl font-semibold py-2 pr-4 pl-3 rounded md:bg-transparent   md:p-0 dark:text-white"
                              aria-current="page"
                              style={{ color: item?.bg_color }}
                            >
                              {item?.name}
                            </a>
                          </li>
                        </Link> */}
                      </>
                    );
                  }
                })}

              {!pageLoading &&
                pageData.data.map((item, k) => {
                  return (
                    <RouteLink
                    key={'s'+k}
                      to={`/page/${item?.slug}`}
                    >
                      <li>
                        <a
                          href={`/page/${item?.slug}`}
                          className="block text-xl font-semibold py-2 pr-4 pl-3 rounded md:bg-transparent  md:p-0 dark:text-white"
                          aria-current="page"
                          style={{ color: item?.bg_color }}
                        >
                          {item?.name}
                        </a>
                      </li>
                    </RouteLink>
                  );
                })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
