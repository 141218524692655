import React, { useEffect, useRef } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import DefaultImg from '../default.jpg'

const SingleCardUI = (props) => {
  const { item } = props;


  const [isFlipped, setIsFlipped] = React.useState(false);
  const [AddClass, setAddClass] = React.useState(false);

  const SAMPLE_MP3_URL = item?.front_audio;

  const ref1 = useRef();
  const ref2 = useRef();

  useEffect(() => {
    // console.log(ref1.current.offsetWidth)
    if(ref2.current.offsetWidth > ref1.current.offsetWidth){
      setAddClass(true);
    }
    // setTimeout(() => { 
    //   console.log(ref2.current.offsetWidth)
    // }, 2000);
  }, [AddClass])

  let cardImg = item?.front_image;
  if(!cardImg){
    // cardImg = 'https://apply.sts.net.pk/assets/images/default-upload-image.jpg'
    cardImg = DefaultImg
  }
  


  return (
    <>
      <div className="card " title="click here">
        <div className={`card__inner ${ isFlipped ? 'is-flipped' : ""}`}>
          <div className="card__face card__face--front" style={{ 
            backgroundImage: `linear-gradient(to bottom right, ${item?.item_color?.color1}, ${item?.item_color?.color2})`
           }} onMouseLeave={()=>setIsFlipped(false)} onClick={()=>setIsFlipped(false)}>
            <h2>{item?.back_title}</h2>
          </div>
          <div className="card__face card__face--back" onClick={()=>setIsFlipped(true)}>
            <div className="card__content">
              <div className="card__header" style={{ 
                backgroundImage: `linear-gradient(to bottom right, ${item?.item_color?.color1}, ${item?.item_color?.color2})`
              }} >
                <img
                  src={cardImg}
                  alt={item?.front_title}
                  className="pp"
                />
                
                <div className={`title_parent ${AddClass ? 'marquee' : ''}`} ref={ref1} >
                  <h2 ref={ref2} >{item?.front_title}</h2>
                </div>
              </div>
              <div className="card__body">
                <div  style={{whiteSpace: "pre-line"}}>
                  {/* <h3>{item?.front_title}</h3> */}
                  <p>{item?.front_description}</p>
                </div>
                <div>
                
                {
                  SAMPLE_MP3_URL ? (
                    <AudioPlayer
                      src={SAMPLE_MP3_URL}
                      autoPlayAfterSrcChange={false}
                      showJumpControls={false}
                      layout="horizontal"
                      customControlsSection={[
                        // <div>This is an additional module in controls section </div>,
                        // RHAP_UI.ADDITIONAL_CONTROLS,
                        // RHAP_UI.MAIN_CONTROLS,
                        RHAP_UI.MAIN_CONTROLS,
                        // RHAP_UI.VOLUME_CONTROLS,
                      ]}
                    />
                  )
                  :
                  null
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleCardUI;
