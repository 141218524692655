import { Carousel } from "flowbite-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { bannerAction } from "../redux/actions";
import LoadingIcon from "./LoadingIcon";

const Slider = (props) => {

  const { loading:bannersLoading, data: bannersData } = useSelector(state=>state.BannerReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    if(bannersLoading){
      dispatch(bannerAction());
    }
  }, [dispatch, bannersLoading]);

  
  if(bannersLoading ){
    return <LoadingIcon />
  }

  return ( 
    <>
      <div className="">
      {
        bannersLoading ?
        <>
          loading...
        </>
        :
        <>
        <Swiper
            className="external-buttons"
            modules={[Navigation]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            // breakpoints={{
            //   640: {
            //     slidesPerView: 2,
            //   },
            //   768: {
            //     slidesPerView: 2,
            //   },
            //   1024: {
            //     slidesPerView: 3,
            //     // spaceBetween: 50,
            //   },
            //   1280: {
            //     slidesPerView: 4,
            //     // spaceBetween: 50,
            //   },
            // }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
          >
          { !bannersLoading && bannersData.data.length > 0 && 
                bannersData.data.map((item, k)=>{
                  return (
                    <>
                    <SwiperSlide key={k}>
                        <img
                          src={item.image}
                          alt={item.name}
                        />
                    </SwiperSlide>
                    </>
                  )
                })
              }
        </Swiper>
        </>

      }
      </div>
    </>
  );
};

export default Slider;
