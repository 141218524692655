import axios from "axios";

axios.interceptors.request.use((config) => {
    
  return config;
});

export const request = {
  get: axios.get,
};

export const requestPost = {
  post: axios.post,
};
