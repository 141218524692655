import { AiFillYoutube } from "react-icons/ai"; 

import { Footer } from "flowbite-react";
import moment from "moment/moment";
import React from "react";
import { 
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsGithub,
  BsDribbble
  
 } from "react-icons/bs";


const FooterSec = () => {
  return (
    <>
      <Footer container={true} className="footer-sec">
        <div className="w-full">
          {/* <div className="grid w-full justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
            <div>
              <Footer.Brand
                href="https://flowbite.com"
                src="https://flowbite.com/docs/images/logo.svg"
                alt="Flowbite Logo"
                name="Flowbite"
              />
            </div>
            <div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
              <div>
                <Footer.Title title="about" />
                <Footer.LinkGroup col={true}>
                  <Footer.Link href="#">Flowbite</Footer.Link>
                  <Footer.Link href="#">Tailwind CSS</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.Title title="Follow us" />
                <Footer.LinkGroup col={true}>
                  <Footer.Link href="#">Github</Footer.Link>
                  <Footer.Link href="#">Discord</Footer.Link>
                </Footer.LinkGroup>
              </div>
              <div>
                <Footer.Title title="Legal" />
                <Footer.LinkGroup col={true}>
                  <Footer.Link href="#">Privacy Policy</Footer.Link>
                  <Footer.Link href="#">Terms & Conditions</Footer.Link>
                </Footer.LinkGroup>
              </div>
            </div>
          </div> */}
          <Footer.Divider  className="Footer-Divider" />
          <div className="container mx-auto">
            <div className="w-full sm:flex sm:items-center sm:justify-between">
              <Footer.Copyright className="text-xl" href="/" by="Meinwortschatz.com" year={moment().format('YYYY')} />
              <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center text-white">
                <a className="text-white flex items-center gap-2" href="https://instagram.com/germanizeme2023?igshid=bmh5dnN6NTFpZzR3&utm_source=qr">
                 <BsInstagram /> Germanizeme2023
                </a>
                {/* <AiFillYoutube /> */}
                <Footer.Icon target="_blank" className=" fill-white" href="https://www.youtube.com/@GermanizeMe" icon={AiFillYoutube} />
                {/* <Footer.Icon target="_blank" className=" fill-white" href="#0" icon={BsFacebook} />
                <Footer.Icon target="_blank" className=" fill-white" href="#0" icon={BsInstagram} />
                <Footer.Icon target="_blank" className=" fill-white" href="#0" icon={BsTwitter} />
                <Footer.Icon target="_blank" className=" fill-white" href="#0" icon={BsGithub} />
                <Footer.Icon target="_blank" className=" fill-white" href="#0" icon={BsDribbble} /> */}
              </div>
            </div>
          </div>
        </div>
      </Footer>
    </>
  );
};

export default FooterSec;
