import {
  FooterSec,
  Navbar,
  SingleCardUI,
  Slider,
  CardCarousel,
} from "./components/index";
import "./App.css";
import "react-h5-audio-player/lib/styles.css";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CategoriesAction } from "./redux/actions";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import LoadingIcon from "./components/LoadingIcon";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import HomeSectionData from "./components/HomeSectionData";


const HomePage = () => {


  const { loading: categoriesLoading, data: categoriesData } = useSelector(
    (state) => state.CategoriesReducer
  );
  const { loading: bannersLoading, data: bannersData } = useSelector(
    (state) => state.BannerReducer
  );
  const dispatch = useDispatch();

  
  const { loading:pageLoading, data: pageData } = useSelector(state=>state.PageReducer);


  useEffect(() => {
    if (categoriesLoading) {
      dispatch(CategoriesAction());
    }
  }, [dispatch, categoriesLoading]);
  if (categoriesLoading || pageLoading) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          zIndex: 9999,
          width: "100%",
          height: "100%",
          background: "#ffffff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="loader">Loading...</div>
      </div>
    );
  }


  

  return (
    <>
      <Slider />

      {!categoriesLoading &&
        categoriesData.data.length > 0 &&
        categoriesData.data.map((item, kay) => {

          return <HomeSectionData item={item} kay={`kay__${kay}`} />


          if (item?.flash_cards_count > 0) {
            let secBg = "#d7d9d9";
            if (kay % 2) {
              secBg = "#39a189";
            }
            return (
              <>

                {/* <div
                  key={kay}
                  className="w-full section-cat"
                  style={{ backgroundColor: secBg }}
                  id={`section${kay}`}
                >
                  <div className="container mx-auto py-10 pb-16">
                    <div className="grid grid-cols-1 gap-8">
                      <h3
                        className="text-5xl font-bold text-center mb-2"
                        style={{
                          color: item?.bg_color ? item?.bg_color : "#000",
                        }}
                      >
                        {item.name}
                      </h3>
                    </div>

                    <Box >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="scrollable force tabs example"
                      >
                      {
                        item?.sub_category.map((sItem, sKey)=>{
                          return (
                            <Tab label={sItem?.name} key={sKey} onClick={()=>chooseATab(sItem)} />
                          )
                        })
                      }

                      </Tabs>
                    </Box>
                    <Box>
                      {
                        FilterData.length > 0 ?
                        <>
                          <CardCarousel items={FilterData} />
                        </>
                        :
                        <>
                          <h3 className="text-xl font-bold uppercase text-black pl-16">To be updated!</h3>
                        </>
                      }
                    </Box>
                    

                    <div className="view-all">
                      <Link to={`/book/${btoa(item?.id)}`}>View More</Link>
                    </div>
                  </div>
                </div> */}
              </>
            );
          }
          
        })}
    </>
  );
};

export default HomePage;
