// import { createStore, combineReducers, applyMiddleware } from "redux";
// import thunk from "redux-thunk";
// import { composeWithDevTools } from "@redux-devtools/extension"
// import {BannerReducer, CategoriesReducer } from './reduces/index'


// const reducer = combineReducers({
//     BannerReducer,
//     CategoriesReducer
// });

// let initialState = {};

// const middleware = [thunk];

// const Store = createStore(
//   reducer,
//   initialState,
// //   applyMiddleware(...middleware)
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export default Store;

import { configureStore } from "@reduxjs/toolkit"
import {BannerReducer, CategoriesReducer, CategoryDetailReducer, PageReducer } from './reduces/index'

const Store = configureStore({
  reducer: {
    BannerReducer,
    CategoryDetailReducer,
    PageReducer,
    CategoriesReducer
  },
});

export default Store;

