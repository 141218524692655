
import { createReducer } from "@reduxjs/toolkit"

export const BannerReducer = createReducer({
  loading: true,
  data: [],
  msg: null,
  error: null,
}, {
  BannerReset: (state) => {
    state.loading = false;
    state.data = null;
    state.msg = null;
    state.error = null;
  },
  BannerRequest: (state) => {
    state.loading = true;
  },
  BannerSuccess: (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.msg = null;
    state.error = null;
  },
  BannerFailure: (state, action) => {
    state.loading = true;
    state.data_error = action.payload;
    state.msg = null;
    state.error = null;
  },
});

export const CategoriesReducer = createReducer({
  loading: true,
  data: [],
  msg: null,
  error: null,
}, {
  CategoriesReset: (state) => {
    state.loading = false;
    state.data = null;
    state.msg = null;
    state.error = null;
  },
  CategoriesRequest: (state) => {
    state.loading = true;
  },
  CategoriesSuccess: (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.msg = null;
    state.error = null;
  },
  CategoriesFailure: (state, action) => {
    state.loading = true;
    state.data_error = action.payload;
    state.msg = null;
    state.error = null;
  },
});

export const CategoryDetailReducer = createReducer({
  loading: true,
  data: [],
  msg: null,
  error: null,
}, {
  CategoryDetailReset: (state) => {
    state.loading = false;
    state.data = null;
    state.msg = null;
    state.error = null;
  },
  CategoryDetailRequest: (state) => {
    state.loading = true;
  },
  CategoryDetailSuccess: (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.msg = null;
    state.error = null;
  },
  CategoryDetailFailure: (state, action) => {
    state.loading = true;
    state.data_error = action.payload;
    state.msg = null;
    state.error = null;
  },
});

export const PageReducer = createReducer({
  loading: true,
  data: [],
  msg: null,
  error: null,
}, {
  PageReset: (state) => {
    state.loading = false;
    state.data = null;
    state.msg = null;
    state.error = null;
  },
  PageRequest: (state) => {
    state.loading = true;
  },
  PageSuccess: (state, action) => {
    state.loading = false;
    state.data = action.payload;
    state.msg = null;
    state.error = null;
  },
  PageFailure: (state, action) => {
    state.loading = true;
    state.data_error = action.payload;
    state.msg = null;
    state.error = null;
  },
});
