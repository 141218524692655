import { request as https, requestPost } from "./request";

// const baseUrl = "http://127.0.0.1:8000/api";
const baseUrl = "https://meinwortschatz.com/backend/public/api";

export const bannersRequest = (params) => {  
  return requestPost.post(`${baseUrl}/banners`, params);
};

export const categoriesRequest = (params) => {  
  return requestPost.post(`${baseUrl}/categoryApi_n`, params); 
};

// export const categoriesRequest = (params) => {  
//   return requestPost.post(`${baseUrl}/categories`, params); 
// };

export const categoriesDetailRequest = (params) => {  
  return requestPost.post(`${baseUrl}/sub-category`, params);
};

export const pageRequest = (params) => {  
  return requestPost.post(`${baseUrl}/pages`, params);
};