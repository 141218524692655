import {
  FooterSec,
  Navbar,
  SingleCardUI,
  Slider,
  CardCarousel,
  HeaderSec,
} from "./components/index";
import "./App.css";
import "react-h5-audio-player/lib/styles.css";

import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CategoriesAction, CategoriesDetailAction } from "./redux/actions";
import { Button, ListGroup } from "flowbite-react";
import { useParams } from "react-router";
import { Box, Tab, Tabs } from "@mui/material";

const BookDetailPage = () => {
  let { bookName } = useParams();

  var bName = null;
  // const category_id = 1;
  var category_id = window.atob(bookName);
  const [ActiveChapterColor, setActiveChapterColor] = useState([]);
  const [ActiveColor, setActiveColor] = useState("-1");
  const [ActiveChapter, setActiveChapter] = useState(null);
  const [ActiveChapterData, setActiveChapterData] = useState(null);

  const [ColorActiveChapterData, setColorActiveChapterData] = useState([]);

  const { loading: detailLoading, data: detailData } = useSelector(
    (state) => state.CategoryDetailReducer
  );

  const dispatch = useDispatch();


  const [value, setValue] = useState(0);

  const [ActiveItemColor, setActiveItemColor] = useState({});


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const shuffle = (array) => {
    console.log(array);
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  

  const handleColor = (newValue, item={}) => {

    setActiveItemColor(item);
    let assss = ActiveChapterData;

    // if(Object.keys(item).length > 0){
      assss =  ActiveChapterData.map((ssm, k)=>{
        let a0 = ssm; 
        let flash_cardsItem = ssm.flash_cards.filter((fItm, f0)=>{
          if(newValue !== "-1"){
            if(fItm.item_color_id === item.id){
              return fItm;
            }
          }else{
            return fItm;
          }
        }); 
        
        if(newValue === "-1"){
          flash_cardsItem = shuffle(flash_cardsItem);
        }
        a0 = {...a0, flash_cards:flash_cardsItem};
        return a0;
      })
    // }

    setColorActiveChapterData(assss);
    setActiveColor(newValue);
    return assss;
  };


  const changeChapter = (itemId) => {
    setActiveChapter(itemId);
    let colors0 = [];
    const newData = detailData.data.filter(itm=> itm.id === itemId);
    let singleNewData = newData[0];
    let flash_cards = singleNewData.flash_cards;
    flash_cards.map((fItem, kk)=>{
      if(!colors0.map((c1)=>c1.id).includes(fItem.item_color.id)){
        colors0.push(fItem.item_color)
      }
    })
    
    setActiveChapterColor(colors0)
    
    setActiveColor("-1")
    setColorActiveChapterData(newData)
    setActiveChapterData(newData)
    return newData;
  };


  const requestSearch = (evt) => {
    let value = evt.target.value;
    value = value.toLowerCase()
    let currentChangeChapter = handleColor(ActiveColor, ActiveItemColor);

    let assss = currentChangeChapter;
    if(value.length){
       assss =  currentChangeChapter.map((ssm, k)=>{
        let a0 = ssm; 
        let flash_cardsItem = ssm.flash_cards.filter((fItm, f0)=>{
          if(fItm?.front_title.toLowerCase().match(value)){
            return fItm;
          }
        });
        a0 = {...a0, flash_cards:flash_cardsItem};
        return a0;
      })
    }
    
    setColorActiveChapterData(assss);
  }


  useEffect(() => {
    dispatch(CategoriesDetailAction({ category_id }));
  }, [dispatch, bookName])

  useEffect(() => {
    if(!detailLoading){
      window.scroll(0, 0);
      changeChapter(detailData.data[0].id)
    }
  }, [dispatch, detailLoading]);

  useEffect(() => {
    
  }, [ActiveColor])
  

  

  if(detailLoading ){
    return <div style={{ position: 'fixed', top: 0, zIndex: 9999, width: '100%', height: '100%', background: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="loader">Loading...</div>
    </div>
  }

  return (
    <>
      <HeaderSec title={detailData?.category?.name ? detailData?.category?.name : "Detail"} />

      <div className="container mx-auto py-10 pb-0">
          <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
              >
                {detailData.data &&
                  detailData.data.length > 0 &&
                  detailData.data.map((sItem, sKey) => {
                  return (
                    <Tab
                      className={`chapterTab ${sKey === value ? 'chapterTabActive' : ''}`}
                      label={sItem?.name}
                      key={`sKey_${sKey}`}
                      onClick={() => changeChapter(sItem.id)}
                      // onClick={() => chooseATab(sItem)}
                    />
                  );
                })}
              </Tabs>
            </Box>
            <>

                

              {
                ActiveChapterColor.length > 0  && (
                  <Box sx={{ 
                    display:'flex',
                    justifyContent:'center',
                    gap:'1rem',
                    flexWrap:'wrap'
                  }} marginTop={2} >
                  <div style={{ 
                          width:'80px',
                          height:'40px',
                          backgroundColor: ``,
                          borderRadius:'5px',
                          border: '4px solid #898989',
                          display:'flex',
                          justifyContent:'center',
                          alignItems:'center',
                          cursor:'pointer'
                        }} className={`${ActiveColor === "-1" ? 'activeColor' : ''}`} onClick={()=>handleColor("-1")}> All Colors </div>

                  {
                    ActiveChapterColor.map((cItem, k)=>{
                      return (
                        <div style={{ 
                          width:'80px',
                          height:'40px',
                          border: '4px solid #898989',
                          backgroundImage: `linear-gradient(to bottom right, ${cItem?.color1}, ${cItem?.color2})`,
                          borderRadius:'5px',
                          cursor:'pointer'
                        }} className={`${ActiveColor == k ? 'activeColor' : ''}`} onClick={()=>handleColor(k, cItem)}>

                        </div>
                      )
                    })
                  }
                  <input type={'text'} onChange={(e)=>requestSearch(e)} placeholder="Search" style={{
                    borderRadius:'5px'
                  }}/>
                  </Box>
                )
              }
            </>
      </div>

    <div className="container mx-auto py-10 pb-16">
        <div className="grid grid-cols-1 gap-8">

          <div>
           {
            ColorActiveChapterData && ColorActiveChapterData.map((item, a0)=>{
              return (
                <>
                <div className="grid grid-cols-1 gap-8  sm:px-0 px-2" key={'act'+a0}>
                  <div className="my-2 mb-4">
                    <h3 className="text-2xl font-bold uppercase">{item?.name}</h3>
                    <hr />
                  </div>
                </div>
                {
                  item.flash_cards.length > 0 ?
                  <>
                  <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 sm:px-0 px-2">
                    { item.flash_cards &&
                      item.flash_cards.map((fCItem, fk) => {
                        return <SingleCardUI key={`fk_${fk}`} item={fCItem} />;
                      })}
                  </div>
                  </>
                  :
                  <>
                  <h3 className="text-xl font-thin uppercase">To be updated!</h3>
                  </>

                }
                
                </>
              )
            })
           }
          </div>
        </div>
      </div>

    </>
  );
};

export default BookDetailPage;
