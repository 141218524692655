import React from 'react'
import { Outlet } from 'react-router-dom'
import { FooterSec, Navbar, Slider } from './components'

const Layout = () => {
    return (
        <>
            <Navbar />
            <div style={{ 
                minHeight:'90vh'
             }}>
            <Outlet />
            </div>
            <FooterSec />
        </>
    )
}

export default Layout