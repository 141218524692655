import {
  FooterSec,
  Navbar,
  SingleCardUI,
  Slider,
  CardCarousel,
  HeaderSec,
} from "./components/index";
import "./App.css";
import "react-h5-audio-player/lib/styles.css";

import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CategoriesAction, CategoriesDetailAction, PagesAction } from "./redux/actions";
import { Button, ListGroup } from "flowbite-react";
import { useParams } from "react-router";
import { Box, Tab, Tabs, Typography } from "@mui/material";

const PageDetail = () => {
  let { pageName } = useParams();

  const [DetailData, setDetailData] = useState(null);

  const { loading: pageLoading, data: pageData } = useSelector(
    (state) => state.PageReducer
  );


  const dispatch = useDispatch();

  useEffect(() => {
    
    if(pageLoading){
      dispatch(PagesAction());
    }
if(!pageLoading){
  let pageDetail = pageData.data.filter((itm)=>itm.slug == pageName);
    if(pageDetail.length > 0){
      setDetailData(pageDetail[0])
    }
}
  }, [pageLoading, pageName])
  

  

  if(!DetailData){
    return <div style={{ position: 'fixed', top: 0, zIndex: 9999, width: '100%', height: '100%', background: '#ffffff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="loader">Loading...</div>
    </div>
  }

  return (
    <>
      <HeaderSec title={DetailData?.name ? DetailData?.name : "Detail"} />

      <div className="container mx-auto py-10 ">
            <Typography variant="div" dangerouslySetInnerHTML={{__html: DetailData?.description}} />
      </div>

    </>
  );
};

export default PageDetail;
