import {
  FooterSec,
  Navbar,
  SingleCardUI,
  Slider,
  CardCarousel,
} from "./components/index";
import "./App.css";
import "react-h5-audio-player/lib/styles.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CategoriesAction, PagesAction } from "./redux/actions";
import { Button } from "flowbite-react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import HomePage from "./HomePage";
import Layout from "./Layout";
import BookDetailPage from "./BookDetailPage";
import PageDetail from "./PageDetail";


const App = () => {
  
  const { loading:categoriesLoading, data: categoriesData } = useSelector(state=>state.CategoriesReducer);
  const { loading:pageLoading, data: pageData } = useSelector(state=>state.PageReducer);
  const { loading:bannersLoading, data: bannersData } = useSelector(state=>state.BannerReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    
    if(categoriesLoading){
      dispatch(CategoriesAction());
    }

    if(pageLoading){
      dispatch(PagesAction());
    }
    
  }, [dispatch, categoriesLoading]);
  
  
  return (
    <>
    
     <BrowserRouter>
     <Routes>
        <Route path="/" element={<Layout />}  >
            <Route index element={<HomePage />}  />
            <Route exact path='/book/:bookName' element={<BookDetailPage />}  />
            <Route exact path='/page/:pageName' element={<PageDetail />}  />
        </Route>
     </Routes>
     </BrowserRouter>
     
    </>
  );
}

export default App;
