import { Carousel } from "flowbite-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bannerAction } from "../redux/actions";
import IMAGES from '../IMAGES.jpg'

const HeaderSec = (props) => {
  
  const {title} = props;

  return ( 
    <>
      <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 header-sec" style={{ 
        backgroundImage: `url('${IMAGES}')`
       }}>
        <div className="container mx-auto position-relative">
          <h1 className="hTitle text-white">{title}</h1>
        </div>
      </div>
    </>
  );
};

export default HeaderSec;
